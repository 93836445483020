import { contacts, contactsBy, contactsKz } from "../../../pages-data/_V2/common/contacts"
import {Locales} from "../../../localization/types";

const data = {
	title: "Я всё понял, погнали!",
	subTitle: "позвонить и оформить заявку",
	phone: contacts.phone,
	ctaButtonText: "Заказать звонок",
}

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			...data,
			phone: contactsKz.phone
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Мен бәрін түсіндім, кеттік!",
			subTitle: "ҚОҢЫРАУ ШАЛУ ЖӘНЕ ӨТІНІМДІ РЕСІМДЕУ",
			phone: contactsKz.phone,
			ctaButtonText: "Қоңырауға тапсырыс беру",
		}
	}

	if (locale === 'ru-BY') {
		return {
			...data,
			phone: contactsBy.phone
		}
	}

	return {
		...data,
	}
}
