import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";


export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			subTitle: "быстрый запуск с комплексом услуг",
			title: "Нет времени? \nНет проблем.",
			buttonCta: "Добавить",
			serviceCards: [
				{
					id: 1,
					serviceTitle: "Настроить систему",
					serviceDetails:
						"Подключение к Web kassa\nи оператору фискальных данных",
					serviceIllustration: (
						<StaticImage
							className={styles.servicesIllustration}
							src="./assets/setup.png"
							breakpoints={[315, 630, 826, 1652, 406, 812]}
							sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
							alt="Настройка системы Quick Resto"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					servicePrice: "от 11 000 ₸",
					serviceLink: pagesLinks.service,
				},
				{
					id: 2,
					serviceTitle: "Заказать оборудование",
					serviceDetails:
						"Совместимый комплект устройств, быстрая доставка, гарантия",
					serviceIllustration: (
						<StaticImage
							className={styles.servicesIllustration}
							src="./assets/hardware.png"
							breakpoints={[315, 630, 826, 1652, 406, 812]}
							sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
							alt="Оборудование для ресторанов"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					servicePrice: "от 100 000 ₸",
					serviceLink: pagesLinks.service,
				},
				{
					id: 3,
					serviceTitle: "Обучить сотрудников",
					serviceDetails:
						"Проверенные практики автоматизации общепита, инструкции по управлению системой",
					serviceIllustration: (
						<StaticImage
							className={styles.servicesIllustration}
							src="./assets/onboard.png"
							breakpoints={[315, 630, 826, 1652, 406, 812]}
							sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
							alt="Обучение персонала ресторана"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					servicePrice: "от 27 000 ₸",
					serviceLink: pagesLinks.service,
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			subTitle: "қызметтер кешенімен жылдам іске қосу",
			title: "Уақыт жоқ па?\nҚиындығы жоқ.",
			buttonCta: "Қосу",
			serviceCards: [
				{
					id: 1,
					serviceTitle: "Жүйені теңшеу",
					serviceDetails:
						"Webkassa-ға және фискалдық\nдеректер операторына қосылу",
					serviceIllustration: (
						<StaticImage
							className={styles.servicesIllustration}
							src="./assets/setup.png"
							breakpoints={[315, 630, 826, 1652, 406, 812]}
							sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
							alt="Настройка системы Quick Resto"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					servicePrice: "11 000 ₸ бастап",
					serviceLink: pagesLinks.service,
				},
				{
					id: 2,
					serviceTitle: "Жабдыққа тапсырыс беру",
					serviceDetails:
						"Құрылғылардың үйлесімді жиынтығы, тез жеткізу, кепілдік беру",
					serviceIllustration: (
						<StaticImage
							className={styles.servicesIllustration}
							src="./assets/hardware.png"
							breakpoints={[315, 630, 826, 1652, 406, 812]}
							sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
							alt="Оборудование для ресторанов"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					servicePrice: "100 000 ₸ бастап",
					serviceLink: pagesLinks.service,
				},
				{
					id: 3,
					serviceTitle: "Қызметкерлерді үйрету",
					serviceDetails:
						"Қоғамдық тамақтандыруды автоматтандырудың тексерілген тәжірибелері, жүйені басқару жөніндегі нұсқаулықтар",
					serviceIllustration: (
						<StaticImage
							className={styles.servicesIllustration}
							src="./assets/onboard.png"
							breakpoints={[315, 630, 826, 1652, 406, 812]}
							sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
							alt="Обучение персонала ресторана"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					servicePrice: "27 000 ₸ бастап",
					serviceLink: pagesLinks.service,
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			subTitle: "быстрый запуск с комплексом услуг",
			title: "Нет времени? \nНет проблем.",
			buttonCta: "Добавить",
			serviceCards: [
				{
					id: 1,
					serviceTitle: "Настроить систему",
					serviceDetails:
						"Полная автоматизация, консультации по оформлению кассы",
					serviceDetailsClass: styles.text__tablet,
					serviceIllustration: (
						<StaticImage
							className={styles.servicesIllustration}
							src="./assets/setup.png"
							breakpoints={[315, 630, 826, 1652, 406, 812]}
							sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
							alt="Настройка системы Quick Resto"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					servicePrice: "от 90 Р",
					serviceLink: pagesLinks.service,
				},
				{
					id: 2,
					serviceTitle: "Заказать оборудование",
					// Ниже в тексте внутри «54-ФЗ» — неразрывный дефис: «‑» или &#8209;
					serviceDetails:
						"Совместимый комплект устройств, быстрая доставка, гарантия",
					serviceIllustration: (
						<StaticImage
							className={styles.servicesIllustration}
							src="./assets/hardware.png"
							breakpoints={[315, 630, 826, 1652, 406, 812]}
							sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
							alt="Оборудование для ресторанов"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					servicePrice: "от 400 Р",
					serviceLink: pagesLinks.service,
				},
				{
					id: 3,
					serviceTitle: "Обучить сотрудников",
					serviceDetails:
						"Проверенные практики автоматизации общепита, инструкции по управлению системой",
					serviceIllustration: (
						<StaticImage
							className={styles.servicesIllustration}
							src="./assets/onboard.png"
							breakpoints={[315, 630, 826, 1652, 406, 812]}
							sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
							alt="Обучение персонала ресторана"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					servicePrice: "от 300 Р",
					serviceLink: pagesLinks.service,
				},
			],
		}
	}

	return {
		subTitle: "быстрый запуск с комплексом услуг",
		title: "Нет времени? Нет проблем.",
		buttonCta: "Добавить",
		serviceCards: [
			{
				id: 1,
				serviceTitle: "Настроить систему",
				serviceDetails:
					"Полная автоматизация, оформление онлайн кассы в налоговой, выпуск цифровой подписи и сертификат ОФД",
				serviceIllustration: (
					<StaticImage
						className={styles.servicesIllustration}
						src="./assets/setup.png"
						breakpoints={[315, 630, 826, 1652, 406, 812]}
						sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
						alt="Настройка системы Quick Resto"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
				servicePrice: "от 1990 ₽",
				serviceLink: pagesLinks.shop.services.systemSetup,
			},
			{
				id: 2,
				serviceTitle: "Заказать оборудование",
				// Ниже в тексте внутри «54-ФЗ» — неразрывный дефис: «‑» или &#8209;
				serviceDetails:
					"Совместимый комплект устройств, быстрая доставка, соответствие\n54‑ФЗ, гарантия",
				serviceIllustration: (
					<StaticImage
						className={styles.servicesIllustration}
						src="./assets/hardware.png"
						breakpoints={[315, 630, 826, 1652, 406, 812]}
						sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
						alt="Оборудование для ресторанов"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
				servicePrice: "от 13 300 ₽",
				serviceLink: pagesLinks.shop.index,
			},
			{
				id: 3,
				serviceTitle: "Обучить сотрудников",
				serviceDetails:
					"Проверенные практики автоматизации общепита, инструкции по управлению системой",
				serviceIllustration: (
					<StaticImage
						className={styles.servicesIllustration}
						src="./assets/onboard.png"
						breakpoints={[315, 630, 826, 1652, 406, 812]}
						sizes="(max-width: 500px) 315px, (max-width: 990px) 826px, (max-width: 1319px) 440px, (min-width: 1320px) 406px"
						alt="Обучение персонала ресторана"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
				servicePrice: "от 4990 ₽",
				serviceLink: pagesLinks.service,
			},
		],
	}
}
