import classNames from "classnames"
import React from "react"
import Slider from "react-slick"
import AdaptiveLink from "../../../components/_V2/AdaptiveLink"
import CartIcon from "./assets/CartIcon"
import { getData } from "./data"
import "./slick-styles.scss"
import styles from "./styles.module.scss"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

// Цвет слайда
enum THEME_TYPE {
	GRAY_LIGHT = "gray-light",
	WHITE = "white",
}

interface Props {
	className?: string
	theme?: THEME_TYPE
}

export default function AdditionalServices(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale)

	const theme =
		props.theme === undefined ? THEME_TYPE.GRAY_LIGHT : THEME_TYPE.WHITE

	const settings = {
		arrows: false,
		slidesToShow: 3,
		slidesToScroll: 0,
		infinite: false,
		pauseOnDotsHover: true,
		pauseOnFocus: true,
		pauseOnHover: true,
		customPaging: function() {
			return <button className="customDotButton" />
		},
		appendDots: function(dots: React.ReactChild) {
			return <ul>{dots}</ul>
		},
		dotsClass: "customSlickDot",
		responsive: [
			{
				breakpoint: 1319,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					dots: true,
					infinite: false,
					autoplay: true,
					autoplaySpeed: 5000,
					speed: 500,
				},
			},
			{
				breakpoint: 999,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					infinite: false,
					autoplay: true,
					autoplaySpeed: 5000,
					speed: 500,
				},
			},
		],
	}

	return (
		<section
			className={classNames(
				styles.section,
				props.className,
				"additionalServices"
			)}
		>
			<div className={styles.content}>
				<p className={styles.subTitle}>{data.subTitle}</p>
				<h2
					className={classNames(styles.title,
						{[styles.title__pre]: localizationContext.locale !== 'ru-RU'},
						{[styles.title__kz]: localizationContext.locale === 'kz-KZ'})}
				>
					{data.title}
				</h2>
			</div>
			<Slider {...settings}>
				{data.serviceCards.map(service => (
					<div
						className={classNames(styles.service, {
							[styles.grayLight]: theme === THEME_TYPE.GRAY_LIGHT,
							[styles.white]: theme === THEME_TYPE.WHITE,
						})}
						key={service.id}
					>
						{
							service.serviceLink.href &&
							<AdaptiveLink
								className={classNames(styles.serviceBlockLink, {[styles.serviceLink__kz]: localizationContext.locale === 'ru-KZ'})}
								useGatsbyLink={service.serviceLink.useGatsbyLink}
								href={service.serviceLink.href}
							>
								{""}
							</AdaptiveLink>
						}

						{service.serviceIllustration}
						<div className={styles.serviceContentWrapper}>
							<div className={styles.serviceHeading}>
								<h3 className={styles.serviceTitle}>{service.serviceTitle}</h3>
								<p className={classNames(styles.serviceDetails, service?.serviceDetailsClass)}>
									{service.serviceDetails}
								</p>
							</div>
							<div className={styles.serviceButton}>
								{service.serviceLink.href &&
									<AdaptiveLink
										className={classNames(styles.serviceLink)}
										useGatsbyLink={service.serviceLink.useGatsbyLink}
										href={service.serviceLink.href}
									>
										<>
											<CartIcon />
											{data.buttonCta}
										</>
									</AdaptiveLink>
								}

								<p className={classNames(styles.servicePrice, {[styles.servicePrice__kz]: localizationContext.locale === 'ru-KZ'} )}>{service.servicePrice}</p>
							</div>
						</div>
					</div>
				))}
			</Slider>
		</section>
	)
}
