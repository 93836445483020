import * as React from "react"

const CartIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={21}
		height={19}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.188 3.626H19.32a.439.439 0 0 1 .19.035c.06.026.113.064.153.112a.354.354 0 0 1 .068.345l-2.056 6.215a.766.766 0 0 1-.296.391.866.866 0 0 1-.49.151H7.21"
			stroke="#fff"
			strokeWidth={2}
			strokeMiterlimit={10}
		/>
		<path
			d="M17.493 14.318H8.648a.822.822 0 0 1-.79-.588L4.511 2.569a.822.822 0 0 0-.79-.588H.633"
			stroke="#fff"
			strokeWidth={2}
			strokeMiterlimit={10}
		/>
		<path
			d="M15.7 17.489a.77.77 0 1 0 0-1.54.77.77 0 0 0 0 1.54ZM9.48 17.489a.77.77 0 1 0 .001-1.54.77.77 0 0 0 0 1.54Z"
			stroke="#fff"
			strokeWidth={1.559}
			strokeMiterlimit={10}
		/>
	</svg>
)

export default CartIcon
